import React from "react";
import L from "leaflet";
import { Marker, Popup } from "react-leaflet";
import iconUrl from "../assets/images/icon.png";
import { useRef } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";

export default function CustomMarker({ item, live }) {
  const markerRef = useRef(null);
  const customIcon = L.icon({
    iconUrl: iconUrl,
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32],
  });

  const _projectFirstPage = item?.Pages[0];

  useEffect(() => {
    const marker = markerRef.current;
    if (marker) {
      marker.openPopup();
    }
  }, []);

  const locationMarker = ({ project, live }) => {
    let _data = live?.[project._id];
    const _map = project?.config?.map;
    return (
      <div className="map">
        <div className="text-center">
          <Link
            className="title"
            to={
              _projectFirstPage
                ? `/project/${item._id}/${_projectFirstPage._id}`
                : ""
            }
          >
            {project?.name}
          </Link>
        </div>
        {_map &&
          _map?.show &&
          _map?.items?.map((item, i) => (
            <div key={i} className="values">
              <span>{item?.title} : </span>
              {_data && <span>{_data?.rawJson?.[item.value]}</span>}
              {item?.units && (
                <span className="text-orange-500 text-xs"> {item?.units}</span>
              )}
            </div>
          ))}
      </div>
    );
  };

  const _latitude = item?.latitude || -10;
  const _longitude = item?.longitude || 78.9629;
  return (
    <>
      <Marker
        key={item._id}
        position={[_latitude, _longitude]}
        icon={customIcon}
        ref={markerRef}
      >
        <Popup autoClose={false} closeOnClick={false}>
          {locationMarker({ project: item, live })}
        </Popup>
      </Marker>
    </>
  );
}
