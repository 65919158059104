import React from "react";
import { Link, useParams } from "react-router-dom";
import { encodeUrl } from "../utils/URLEncoding";

export default function AdvancedFilter({ config, type }) {
  const { projectId } = useParams();
  let _config = JSON.stringify({ config, type });

  return (
    <Link to={`/reports/chart/${projectId}/${encodeUrl(_config)}`}>
      <div>
        <i className="pi pi-filter"></i> Advanced
      </div>
    </Link>
  );
}
