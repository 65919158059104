import React, { useState } from "react";
import { CustomCalander, CustomDropDown } from "../shared/AllInputs";
import { Button } from "primereact/button";
import { useEffect } from "react";
import moment from "moment";
import Grid, { Col } from "../shared/Grid";
import { useSelector } from "react-redux";
import formValidation from "../utils/validations";
import { showFormErrors } from "../utils/commonFunctions";
import { getProjectReportAction } from "../store/actions/projectActions";
import { useParams } from "react-router-dom";
import { decodeUrl } from "../utils/URLEncoding";
import BarChart from "../charts/BarChart/BarChart";
import useProject from "../hooks/useProject";
import CustomCard from "../shared/CustomCard";
import LineChart from "../charts/LineChart/LineChart";

const intervalOptions = [
  { name: "Hourly", value: "HOURLY" },
  { name: "Daily", value: "DAILY" },
];

export default function ChartReports() {
  let { config } = useParams();
  config = decodeUrl(config);
  config = JSON.parse(config);

  const { project } = useProject();
  const { dataHour } = useSelector((state) => state.project);
  const _dataHour = dataHour[project?._id];

  const _title = config?.config?.title;
  const _config = config?.config;
  const _type = config?.type;
  useEffect(() => {
    setProjectData(_dataHour);
  }, [_dataHour]);

  const [projectData, setProjectData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    interval: "HOURLY",
    startDate: "",
    endDate: "",
  });

  const onReset = () => {
    setData({ interval: "HOURLY", startDate: "", endDate: "" });
    setProjectData(_dataHour);
  };

  const handleChange = ({ name, value }) => {
    const formErrors = formValidation(name, value, data);
    setData((prev) => ({ ...prev, [name]: value, formErrors }));
  };

  const handleExport = () => {
    if (showFormErrors(data, setData)) {
      getProjectReportAction({ ...data, project }, setLoading, (res) => {
        setProjectData(res?.data);
      });
    }
  };
  return (
    <Grid>
      <CustomDropDown
        col={4}
        name="interval"
        data={data}
        options={intervalOptions}
        onChange={handleChange}
        placeholder="Select Interval"
      />

      <CustomCalander
        col={4}
        data={data}
        name="startDate"
        onChange={handleChange}
        maxDate={data.endDate}
        minDate={new Date(moment().subtract(365, "days"))}
        showTime={data.interval === "HOURLY"}
        hourFormat="12"
      />
      <CustomCalander
        col={4}
        data={data}
        name="endDate"
        onChange={handleChange}
        minDate={data.startDate}
        maxDate={new Date()}
        showTime={data.interval === "HOURLY"}
        hourFormat="12"
      />

      <Col size={7}>
        <Button
          loading={loading}
          onClick={handleExport}
          severity="primary"
          label="Update"
        />
        <Button
          className="mx-2"
          onClick={onReset}
          severity="secondary"
          label="Reset"
        />
      </Col>
      <CustomCard title={_title} col={12}>
        {_type === "BAR" ? (
          <BarChart config={_config} data={projectData} />
        ) : (
          <LineChart config={_config} data={projectData} height={350} />
        )}
      </CustomCard>
    </Grid>
  );
}
